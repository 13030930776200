import { $api } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import { AxiosRequestConfig, BaseResponse } from "@/services/types";
import { User } from "./types";

const BASE_URL_V1_USER = HOST_API_V1 + "admin/user";

export const getUserList = async (
  params: AxiosRequestConfig
): Promise<BaseResponse<User[]>> => {
  const res = await $api().get(BASE_URL_V1_USER, params);
  return res.data;
};

export const getUserById = async (userId: number) => {
  const res = await $api().get(`${BASE_URL_V1_USER}/${userId}`);
  return res.data;
};

export const deleteUserById = async (userId: number) => {
  const res = await $api().delete(`${BASE_URL_V1_USER}/${userId}`);
  return res.data;
};
